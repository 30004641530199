/**
 * WordPress Front Page stylesheet
 *
 * @since    3.0.0
 * @author   <corey@chatmandesign.com>
 */

// Some of this could probably be moved to components but it's easier to have it
// consolidated here for now.

@import "../../global/styles/lib";

.hero-image {
	width: 100%;
	aspect-ratio: 3/2;
	object-fit: cover;
} // .hero-image

.home-slider {
	.slick-slide {
		aspect-ratio: 3/2;
		overflow: hidden;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
} // .home-slider

@include breakpoint(medium) {
	.hero-image {
		aspect-ratio: 3/1;
	} // .hero-image
} // medium

@include breakpoint(large) {
	.home-slider {
		.slick-slide {
			aspect-ratio: auto;
			height: 65vh;
			max-height: rem-calc(900);
		}
	} // .home-slider
} // large
